import React from "react"

import Typography from "@material-ui/core/Typography"
import Container from "@material-ui/core/Container"

import SEO from "../components/seo"
import Header from "../components/header"
import Footer from "../components/footer"

const NotFound = () => (
  <>
    <SEO title="Page not found" />
    <Header />
    <div
      style={{
        marginTop: "75px",
        paddingBottom: "25px",
        backgroundColor: "whitesmoke"
      }}
    >
      <Container style={{ paddingTop: 16 }}>
        <Typography variant="h1" align="center">
          {" "}
          Page not found
        </Typography>
        <Typography variant="h6" align="center">
          Oops! The page you are looking for has been removed or relocated.
        </Typography>
      </Container>
    </div>
    <div style={{ position: "fixed", bottom: 0 }}>
      <Footer />
    </div>
  </>
)

export default NotFound
